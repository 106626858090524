import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private httpClient = inject(HttpClient);

    public getDocumentType(): Observable<TypeDocument> {
        const url = `${environment.apiUrl}taxonomies/document-types`;
        return this.httpClient.get<Response<TypeDocument>>(url).pipe(
            map((res) => res.data)
        );
    }
}

export interface TypeDocument {
    id: number,
    value: string
}
