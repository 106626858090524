import { createSelector } from '@ngrx/store';
import { SelectAuthorizations } from '../actions/authorizatios.actions';
import { AuthorizationsState } from '../reducers/authorizations.reducers';

const selectFeature = (state: any) => state.authorizations as AuthorizationsState;

export const selectauthorizationsMenu = createSelector(
  selectFeature,
  (state: AuthorizationsState) => {
    return state.authorizationsType?.authorizations.filter(
      (element: SelectAuthorizations) => element.id <= 4
    );
  }
);

export const selectAuthorizationsHistory = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.authorizationsHistory?.authorizationsHistory
);
export const selectAuthorizationsHistoryLoading = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.authorizationsHistory?.isLoading
);
export const selectAuthorizationsNotification = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.authorizationsNotification?.authorizationsNotification
);
export const selectIdAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.active?.active.id
);
export const selectNameAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.active?.active
);

export const selectLabelAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.labelAuthorizations?.authorizations
);
export const selectFirtsLabelAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.labelAuthorizations
);
export const selectFirtsHelpMessengerAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.labelAuthorizations?.payload?.notification
);

export const selectSecondLabelAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.secondLabelAuthorizations?.authorizations
);
export const selectSecondHelpMessengerAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.secondLabelAuthorizations?.payload?.notification
);
export const selectThirdLabelAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.thirdLabelAuthorizations?.authorizations
);

export const selectThirdHelpMessengerAuthorizations = createSelector(
  selectFeature,
  (state: AuthorizationsState) => state.thirdLabelAuthorizations?.payload?.notification
);
export const selectAuthorizationsForm = createSelector(
  selectFeature,
  (state: AuthorizationsState) => {
    return state.form?.form;
  }
);

export const selectContentFormData = createSelector(selectFeature, (state: AuthorizationsState) => {
  return state.formData;
});

export const selectMedicatedMilks = createSelector(selectFeature, (state: AuthorizationsState) => {
  if (!state.formData?.groups?.length) {
    return false;
  }

  return state.formData?.groups[0].id === 204;
});
