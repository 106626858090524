import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { selectTokenGecros } from 'src/app/store/selectors/authentication.selectors';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationsService {
  private httpClient = inject(HttpClient);
  private store = inject(Store);

  public getAuthorizationType(id: number) {
    const url = `${environment.apiUrl}requirements/${id}/groups`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }

  public getAuthorizationForm(groupId: number): Observable<FormElement[]> {
    const url = `${environment.apiUrl}requirements/${groupId}/inputs`;
    return this.httpClient.get<Response<FormElement[]>>(url).pipe(map(({ data }) => data));
  }
  public getAuthorizationHistory(benId: number): Observable<any> {
    const url = `${environment.apiUrl}authorizations/${benId}`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }

  public getAuthorizationNotification(benId: number): Observable<any> {
    const url = `${environment.apiUrl}authorizations/${benId}/notifications`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }

  public getAlertInputAuthorizations(groupId: number, planId: number, provinceId: number) {
    const url = `${environment.apiUrl}requirements/${groupId}/${planId}/${provinceId}/messages`;
    return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data));
  }

  public getProvinces(): Observable<Province[]> {
    const url = `${environment.apiUrl}requirements/provinces`;
    return this.httpClient.get<Response<Province[]>>(url).pipe(map(({ data }) => data));
  }

  public getSubsidiariesByProvinceId(provinceId: number): Observable<Subsidiaries[]> {
    const url = `${environment.apiUrl}requirements/${provinceId}/subsidiaries`;
    return this.httpClient.get<Response<Subsidiaries[]>>(url).pipe(map(({ data }) => data));
  }

  public createFile(payload?: any): Observable<Response<AuthorizationCreateFileResponse>> {
    const url = `${environment.apiUrl}records/authorization`;
    return this.httpClient.post<Response<AuthorizationCreateFileResponse>>(url, payload);
  }

  public uploadFiles(payload: any, inputFiles: any[]): Observable<any> {
    return this.store.select(selectTokenGecros).pipe(
      switchMap(token => {
        const requests = inputFiles
          .filter(input => input.value)
          .map(input => {
            const files = this.getFilesForInput(input);
            const formData = new FormData();
            formData.append('expId', payload.expId);
            formData.append('token', token);
            formData.append('inputName', input.name);

            files.forEach((file, index) => {
              formData.append(`files[${index}]`, file, file.name);
            });

            return this.postAuthorizationFiles(formData);
          });
        return forkJoin(requests);
      })
    );
  }

  public postAuthorizationFiles(request: FormData): Observable<any> {
    const url = `${environment.apiUrl}records/upload`;
    return this.httpClient.post(url, request);
  }

  public getDownloadProofOfAuthorizations(benId: number, authId: number): Observable<string> {
    const url = `${environment.apiUrl}authorizations/${benId}/receipt/${authId}`;
    return this.httpClient.get(url, { responseType: 'text' });
  }

  private getFilesForInput(input: any): File[] {
    return input.value.map((file: any) => {
      const base64String = file.base64;

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: 'image/png' });

      return new File([blob], file.name, { lastModified: new Date().getTime() });
    });
  }
}

export interface Province {
  id: number;
  name: string;
}

export interface Subsidiaries {
  name: string;
  province: string;
  province_id: number;
  subsidiary_id: number;
}
export interface FormElement {
  icon: string | null;
  id: number;
  input: {
    id: number;
    name: string;
    type: 'file' | 'text' | 'date';
    help?: string | null;
    is_required: number;
    is_modal: boolean;
  };
  limit: number | null;
  message: string | null;
}

export interface AuthorizationCreateFileResponse {
  expId: number;
  expNro: number;
  authorization: {
    status: number;
    isSuccessful: boolean;
    data: {
      data: number;
    };
  };
  observations: string;
}
