

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { categoriesApiActions } from '../actions/categories.actions';

@Injectable()
export class CategoriesEffects {
    constructor(private actions$: Actions, private categoriesService: CategoriesService) { }
    categories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(categoriesApiActions.categoriesRequest.type),
            switchMap(() =>
                this.categoriesService.getCategories().pipe(
                    map(payload => categoriesApiActions.categoriesSuccess({ categories: payload })),
                    catchError(() => of(categoriesApiActions.categoriesFailure({ error: 'Error on categories plan' })))
                )
            )
        );
    });

    getCategoryDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(categoriesApiActions.getCategoryDetailRequest.type),
            switchMap((action: any) =>
                this.categoriesService.getCategoryDetailById(action.planId, action.categoryId,).pipe(
                    map(payload => categoriesApiActions.getCategoryDetailSuccess({ data: payload, categoryId: action.categoryId })),
                    catchError(() => of(categoriesApiActions.getCategoryDetailFailure()))
                )
            )
        );
    });

}