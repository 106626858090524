<div class="layout">
  <aside>
    <nobis-aside></nobis-aside>
  </aside>
  <header>
    <nobis-header></nobis-header>
  </header>
  <main>
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
