import { createReducer, on } from '@ngrx/store';
import { planApiActions } from '../actions/plan.action';
import { PlanSummary } from 'src/app/core/model/plan.interfaces';

export interface PlanState {
  isLoading: boolean;
  error: boolean;
  plan?: PlanSummary;


}

export const initialState: PlanState = {
  isLoading: false,
  error: false,
};

export const planReducer = createReducer(
  initialState,
  on(
    planApiActions.planRequest,
    (state): PlanState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    planApiActions.planFailure,
    (state): PlanState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    planApiActions.planSuccess,
    (state, plan): PlanState => ({
      ...state,
      plan: plan.plan,
      isLoading: false,
      error: false,
    })
  ),
);




export const planFeatureKey = 'plan';

