import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Plan, PlanSummary } from '../model/plan.interfaces';
import { Response } from '../model/response';

@Injectable({
    providedIn: 'root'
})
export class PlanService {
    private httpClient = inject(HttpClient);
    public getPlan(idPlan: number): Observable<Plan[]> {
        const url = `${environment.apiUrl}plans/${idPlan}/coverages`
        return this.httpClient.get<Response<Plan[]>>(url).pipe(
            map(({ data }) => data)
        )
    }
    
    public getPlanSummary(idPlan: number): Observable<PlanSummary> {
        const url = `${environment.apiUrl}plans/${idPlan}`
        return this.httpClient.get<Response<PlanSummary>>(url).pipe(
            map(({ data }) => data)
        )
    }
}