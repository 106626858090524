import { createActionGroup, props } from "@ngrx/store";
import { Notification, NotificationAuthorizations } from '../../core/services/notifications.service';

export const notificationApiActions = createActionGroup({
  source: 'Notifications API',
  events: {
    'Notifications Request': props<{ benId: number, page: number }>(),
    'Notifications Success': props<{ notifications: Notification[] }>(),
    'Notifications Failure': props<{ error: string }>(),

    'Notifications Authorizations Request': props<{ benId: number }>(),
    'Notifications Authorizations Success': props<{ notifications: NotificationAuthorizations[] }>(),
    'Notifications Authorizations Failure': props<{ error: string }>(),
  },
});
