import { createActionGroup, props } from "@ngrx/store";
import { Amount, InvoiceData } from "src/app/core/services/accountStatus.service";

export const accountStatusApiActions = createActionGroup({
    source: 'Account Status API',
    events: {
        'History Statement Of Account Request': props<{ benId: number }>(),
        'History Statement Of Account Success': props<{ history: InvoiceData[] }>(),
        'History Statement Of Account Failure': props<{ error: string }>(),

        'Statement Debts Request': props<{ benId: number }>(),
        'Statement Debts Success': props<{ history: Amount[] }>(),
        'Statement Debts Failure': props<{ error: string }>(),
    },
});
