import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginFacade } from './login.facade';
import { RegisterModule } from './register/register.module';

@NgModule({
    declarations: [LoginComponent],
    imports: [CommonModule,
        LoginRoutingModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        RegisterModule,
    ],
    exports: [],
    providers: [LoginFacade],
})
export class LoginModule { }