import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationsService } from '../../core/services/notifications.service';
import { notificationApiActions } from '../actions/notifications.action';
import { page } from '../../core/utils/pages';

@Injectable()
export class NotificationsEffects {
  constructor(private actions$: Actions, private service: NotificationsService) { }

  notifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationApiActions.notificationsRequest.type),
      switchMap((action: Action & { benId: number, page: number }) =>
        this.service.getNotification(action.benId, action.page).pipe(
          map(payload => notificationApiActions.notificationsSuccess({ notifications: payload })),
          catchError(() => of(notificationApiActions.notificationsFailure({ error: 'Error on plan' })))
        )
      )
    );
  });
  
  notificationsAuthorizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(notificationApiActions.notificationsAuthorizationsRequest.type),
      switchMap((action: Action & { benId: number }) =>
        this.service.getNotificationAuthorizations(action.benId).pipe(
          map(payload => notificationApiActions.notificationsAuthorizationsSuccess({ notifications: payload })),
          catchError(() => of(notificationApiActions.notificationsAuthorizationsFailure({ error: 'Error on plan' })))
        )
      )
    );
  });
}
