import { createActionGroup, props } from '@ngrx/store';
import { UserProfile } from 'src/app/core/model/affiliate.interfaces';

export const authApiActions = createActionGroup({
  source: 'Auth API',
  events: {
    'Login Request': props<{ docType: number; docNumber: number; password: string }>(),
    'Login Success': props<{ user: UserProfile }>(),
    'Login Failure': props<{ error: any }>(),
    'Delete Error Login': props<any>(),
    Logout: props,
    'Logout Success': props,
  },
});

