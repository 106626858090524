import { createReducer, on } from '@ngrx/store';
import { notificationApiActions } from '../actions/notifications.action';

export interface NotificationsState {
    notifications?: any;
    notificationsAuthorizations?: any;
}

export const initialState: NotificationsState = {};

export const notificationsReducer = createReducer(
    initialState,
    on(
        notificationApiActions.notificationsRequest,
        (state): NotificationsState => ({ ...state, notifications: { isLoading: true } })
    ),
    on(
        notificationApiActions.notificationsSuccess,
        (state, notifications): NotificationsState => ({ ...state, notifications: { isLoading: false, notifications } })
    ),
    on(
        notificationApiActions.notificationsFailure,
        (state, error): NotificationsState => ({ ...state, notifications: { isLoading: false, error } })
    ),
    on(
        notificationApiActions.notificationsAuthorizationsRequest,
        (state): NotificationsState => ({ ...state, notificationsAuthorizations: { isLoading: true } })
    ),
    on(
        notificationApiActions.notificationsAuthorizationsSuccess,
        (state, notifications): NotificationsState => ({ ...state, notificationsAuthorizations: { isLoading: false, notifications } })
    ),
    on(
        notificationApiActions.notificationsAuthorizationsFailure,
        (state, error): NotificationsState => ({ ...state, notificationsAuthorizations: { isLoading: false, error } })
    ),
);




export const notificationFeatureKey = 'notifications';
