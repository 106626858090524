import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'nobis-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const name = this.formatUrlSegment(event.urlAfterRedirects);
        gtag('event', 'page_view', {
          page_title: document.title + ' | ' + name,
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  protected formatUrlSegment(url: string): string {
    const segments = url.split('/');
    const lastSegment = segments.pop() || '';

    return lastSegment.replace(/-/g, ' ');
  }
}
