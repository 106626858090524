import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CustomMatIconsRegistratorModule } from '../core/modules/custom-mat-icons-registrator.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorIntlSpanish } from './mat-paginator-intl-spanish';


export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'MM/DD/YYYY',
    },
    display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'MM/DD/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

@NgModule({
    exports: [
        CustomMatIconsRegistratorModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
        MatTabsModule,
        MatListModule,
        MatSnackBarModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatExpansionModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule
        
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlSpanish },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
      ]

})
export class MaterialModule { }

