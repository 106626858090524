import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as NgrxStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment.prod';
import { AffiliateEffects } from './effects/affiliate.effects';
import { AuthenticationEffects } from './effects/authentication.effects';
import { CategoriesEffects } from './effects/categories.effects';
import { FamilyGroupEffects } from './effects/familyGroup.effects';
import { metaReducers } from './meta.reducer';
import { AffiliateReducer, affiliateFeatureKey } from './reducers/affiliate.reducer';
import { AuthenticationState, authFeatureKey, authenticationReducer } from './reducers/authentication.reducers';
import { categoriesFeatureKey, categoriesReducer } from './reducers/categories.reducer';
import { familyGroupFeatureKey, familyGroupReducer } from './reducers/familyGroup.reducers';
import { authorizationsReducer, authorizationsFeatureKey } from './reducers/authorizations.reducers';
import { AuthorizationsEffects } from './effects/authorizations.effect';
import { notificationFeatureKey, notificationsReducer } from './reducers/notifications.reducer';
import { NotificationsEffects } from './effects/notifications.effects';
import { accountStatusFeatureKey, accountStatusReducer } from './reducers/accountStatus.reducer';
import { AccountStatusEffects } from './effects/accountStatus.effects';
import { planFeatureKey, planReducer } from './reducers/plan.reducers';
import { PlanEffects } from './effects/plan.effects';

@NgModule({
    imports: [
        NgrxStoreModule.forRoot(
            {
                [authFeatureKey]: authenticationReducer,
                [authorizationsFeatureKey]: authorizationsReducer,
                [familyGroupFeatureKey]: familyGroupReducer,
                [affiliateFeatureKey]: AffiliateReducer,
                [categoriesFeatureKey]: categoriesReducer,
                [notificationFeatureKey]: notificationsReducer,
                [accountStatusFeatureKey]: accountStatusReducer,
                [planFeatureKey]: planReducer,
            },
            { metaReducers }
        ),
        EffectsModule.forRoot([
            AuthenticationEffects,
            AuthorizationsEffects,
            FamilyGroupEffects,
            AffiliateEffects,
            CategoriesEffects,
            NotificationsEffects,
            AccountStatusEffects,
            PlanEffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 20,
            logOnly: environment.production,
        }),
    ]
})
export class StoreModule { }
