import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginFacade } from '../login.facade';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { FormRegisterComponent } from './form-register/form-register.component';
import { RegisterRoutingModule } from './register-routing.module';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { RegisterComponent } from './register.component';
import { PipesModule } from 'src/app/core/utils/pipe/pipe.module';

@NgModule({
  declarations: [RegisterComponent, FormRegisterComponent, RegisterSuccessComponent],
  imports: [CommonModule,
    RegisterRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    ChangePasswordModule,
    PipesModule
  ],
  exports: [],
  providers: [LoginFacade],
})
export class RegisterModule { }