import { Component, Input } from '@angular/core';

@Component({
  selector: 'nobis-required-element',
  templateUrl: './required-element.component.html',
  styleUrls: ['./required-element.component.scss']
})
export class RequiredElementComponent {
  @Input() status!: 1 | 0
  protected getColor(value: number) {

    if (value === 1) {
      return '#E45454';
    } else {
      return '#52B7A0';
    }
  }
}
