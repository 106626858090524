import { createReducer, on } from '@ngrx/store';
import { familyGroupApiActions } from '../actions/familyGroup.actions';

export interface FamilyGroupState {
  isLoading: boolean;
  error: boolean;
  familyGroup?: any;
}

export const initialState: FamilyGroupState = {
  isLoading: false,
  error: false,
};

export const familyGroupReducer = createReducer(
  initialState,
  on(
    familyGroupApiActions.familyGroupRequest,
    (state): FamilyGroupState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    familyGroupApiActions.familyGroupFailure,
    (state): FamilyGroupState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    familyGroupApiActions.familyGroupSuccess,
    (state, familyGroup): FamilyGroupState => ({
      ...state,
      familyGroup,
      isLoading: false,
      error: false,
    })
  ),
);



export const familyGroupFeatureKey = 'familyGroup';

