

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FamilyGroupService } from 'src/app/core/services/familyGroup.service';
import { affiliateApiActions } from '../actions/affiliate.action';

@Injectable()
export class AffiliateEffects {
    constructor(private actions$: Actions, private familyGroupService: FamilyGroupService) { }
    affiliate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(affiliateApiActions.affiliateRequest.type),
            switchMap((action: Action & { benId: number }) =>
                this.familyGroupService.affiliateByBeneficiaryIdService(action.benId).pipe(
                    map(payload => affiliateApiActions.affiliateSuccess({ affiliate: payload })),
                    catchError(() => of(affiliateApiActions.affiliateFailure({ error: 'Error on affiliate' })))
                )
            )
        );
    });
}