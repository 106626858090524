import { createReducer, on } from '@ngrx/store';

import { authApiActions } from '../actions/authentication.actions';

export interface AuthenticationState {
  isLoggingIn: boolean;
  isAuthenticated: boolean;
  error: boolean;
  user?: any;
  response?: any;
}

export const initialState: AuthenticationState = {
  isLoggingIn: false,
  isAuthenticated: false,
  error: false,
};

export const authenticationReducer = createReducer(
  initialState,
  on(
    authApiActions.loginRequest,
    (state): AuthenticationState => ({ ...state, isLoggingIn: true, error: false })
  ),
  on(
    authApiActions.loginFailure,
    (state, payload): AuthenticationState => ({
      ...state,
      isLoggingIn: false,
      error: true,
      response: payload
    })
  ),
  on(
    authApiActions.loginSuccess,
    (state, user): AuthenticationState => ({
      ...state,
      user,
      isAuthenticated: true,
      isLoggingIn: false,
      error: false,
      response: '',
    })
  ),
  on(
    authApiActions.deleteErrorLogin,
    (): AuthenticationState => (
      initialState
    )
  ),
  on(authApiActions.logout, (): AuthenticationState => initialState),

);

// Delete Error Login
export const authFeatureKey = 'auth';
