import { createActionGroup, props } from '@ngrx/store';
import { FamilyMember } from 'src/app/core/model/affiliate.interfaces';


export const familyGroupApiActions = createActionGroup({
  source: 'Family Group API',
  events: {
    'Family Group Request': props<{ benGrId: number }>(),
    'Family Group Success': props<{ familyGroup: FamilyMember[] }>(),
    'Family Group Failure': props<{ error: string }>(),
  },
});


