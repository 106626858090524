import { inject } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { selectIsAuthenticated } from '../store/selectors/authentication.selectors';

export const dashboardGuard: CanActivateFn = (
  _: ActivatedRouteSnapshot,
  stateSnapshot: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store = inject(Store);

  const router = inject(Router);
  const currentUrl = stateSnapshot.url;

  return store.select(selectIsAuthenticated).pipe(
    first(),
    map(isAuthenticated =>
      isAuthenticated ? true : router.createUrlTree(['login'], { queryParams: { r: currentUrl } })
    )
  );
};
