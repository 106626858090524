import { Component, Input } from '@angular/core';

@Component({
    selector: 'nobis-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input() mode: 'primary' | 'outline' | 'disable' = 'primary';
    @Input() disabled = false;

}
