import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfileUser } from 'src/app/store/selectors/authentication.selectors';
import { DashboardFacade } from 'src/app/views/dashboard/dashboard.facade';
import { HeaderFacade } from './header.facade';

@Component({
  selector: 'nobis-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('expandContent', [
      state('false', style({ width: '0px' })),
      state('true', style({ width: '336px' })),
      transition('true <=> false', animate('300ms ease-in-out')),
    ])
  ],
})
export class HeaderComponent {
  private store = inject(Store)
  protected user$ = this.store.select(selectProfileUser);
  protected facade = inject(HeaderFacade);
  private facadeDashboard = inject(DashboardFacade)
  protected isMenuOpen$ = this.facade.isOpenChanges;


  protected menuOpen() {
    this.facade.toggleOpenStatus();
  }

  protected handleLogOut(): void {
    this.facadeDashboard.logOut();
  }


}




