import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Response } from '../model/response';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PasswordService {
    private httpClient = inject(HttpClient);

    public passwordUpdate(payload: PasswordUpdate): any {
        const url = `${environment.apiUrl}password/update`;
        return this.httpClient.post<Response<PasswordUpdate>>(url, payload)
    }
    public forgotPassword(payload: Passwordforgot) {
        const url = `${environment.apiUrl}password/forgot`;
        return this.httpClient.post<Response<PasswordUpdate>>(url, payload)
    }
}
export interface Passwordforgot {
    docType: number,
    docNumber: number,
    email: string
}
export interface PasswordUpdate {
    docType: number,
    docNumber: number,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
}

