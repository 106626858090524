import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgLetModule } from 'ng-let';
import { MaterialModule } from '../../material.module';
import { HeaderComponent } from './header.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';



@NgModule({
  declarations: [
    HeaderComponent, MenuMobileComponent
  ],
  imports: [
    CommonModule, MaterialModule, RouterModule, NgLetModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
