import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PasswordService } from 'src/app/core/services/password.service';
import { RegisterFacade } from '../register/register.facade';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'nobis-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        query(':self', [style({ opacity: 0 }), stagger(100, animate('500ms ease-out', style({ opacity: 1 })))])
      ])
    ])
  ]
})
export class ChangePasswordComponent implements OnInit {
  @Input() nameAction: string = 'TERMINAR REGISTRO' || 'CONTINUAR';
  private fb = inject(FormBuilder);
  private facade = inject(RegisterFacade);
  private service = inject(PasswordService);
  private router = inject(Router);
  protected hide = true;
  protected userData!: any;
  protected changedPassword!: boolean;
  protected messengerError!: string
  protected form: FormGroup = this.fb.group(
    {
      previousPassword: ['', [Validators.required]],
      updatedPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/)]],
      passwordConfirmation: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/)]],

    }, {
    validators: emailMatchValidator(),
  });

  ngOnInit() {
    this.getLocalStorageData();
  }

  protected getLocalStorageData() {
    const userData = localStorage.getItem('User');
    if (userData) {
      this.userData = JSON.parse(userData);
      return this.userData;
    } else {
      return null;
    }
  }

  protected togglePasswordVisibility(event: Event): void {
    event?.stopPropagation();
    this.hide = !this.hide;
  }

  protected returnToPreviousStep() {
    this.facade.stepActive.next(1)
  }

  protected passwordUpdate() {
    const form = {
      docType: this.userData.docType,
      docNumber: this.userData.docNumber,
      currentPassword: this.form.get('previousPassword')!.value,
      newPassword: this.form.get('updatedPassword')!.value,
      confirmPassword: this.form.get('passwordConfirmation')!.value,
    }
    this.service.passwordUpdate(form).pipe(
      
      tap((data: any) => {
        if (this.nameAction === 'CONTINUAR') {
          this.facade.stepActive.next(1)
          this.router.navigate(['login']);
          localStorage.removeItem('User');
        } else {
          this.facade.stepActive.next(1)
          this.router.navigate(['login/exito']);
          localStorage.removeItem('User');
        }

      }),
      catchError((error: any) => {
        this.form.get('previousPassword')?.setErrors({ incorrect: true });
        this.messengerError = error.error.message;
        throw error;
      })
    ).subscribe();
  }
}





export function emailMatchValidator(): ValidatorFn {

  return (control: AbstractControl) => {
    const password = control.get('updatedPassword');
    const repeatPassword = control.get('passwordConfirmation');


    if (password && repeatPassword && password.value !== repeatPassword.value) {
      return { passwordMatch: true };
    }
    return null;
  };
}