<div class="wrapper">
  <button (click)="menuOpen()" color="primary" class="action-mobile" mat-icon-button>
    <mat-icon svgIcon="menu"></mat-icon>
  </button>
  <img src="assets/img/logos/nobis.png" width="156" alt="logo de nobis" />
  <button (click)="handleLogOut()" color="primary" class="action-mobile" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="action">
    <mat-icon>arrow_back</mat-icon>
    <a href="https://nobis.com.ar/" target="_blank"><span>IR AL SITIO</span></a>

    <div class="user">
      <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
      <p>{{ user$ | async }}</p>
    </div>
    <div (click)="handleLogOut()" class="logout">
      <p>SALIR</p>
      <mat-icon>arrow_forward</mat-icon>
    </div>
  </div>
  <ng-container *ngLet="isMenuOpen$ | async as menuOpen">
    <nobis-menu-mobile [@expandContent]="menuOpen" class="nobis-menu-mobile"></nobis-menu-mobile>
  </ng-container>
</div>
