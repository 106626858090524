import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, first, tap } from 'rxjs';
import { selectPlanId } from 'src/app/store/selectors/authentication.selectors';
import { HeaderFacade } from '../header/header.facade';

export interface PageNode {
  id: string;
  label: string;
  navegable: boolean;
  icon?: string;
  relativePath?: string;
  fragment?: string;
  active?: boolean;
  focused?: boolean;
  children?: PageNode[];
}

const PAGES: PageNode[] = [
  {
    label: 'Tu panel general',
    active: true,
    id: 'general',
    navegable: true,
    icon: 'dashboard',
    relativePath: 'mi-panel',
  },
  {
    label: 'Tu grupo familiar',
    active: true,
    navegable: true,
    id: 'family-group',
    icon: 'family-group',
    relativePath: 'grupo-familiar',
  },
  {
    label: 'Credenciales',
    active: true,
    navegable: true,
    id: 'credentials',
    icon: 'credential',
    relativePath: 'credenciales',
  },
  {
    label: 'Tu cobertura',
    active: true,
    navegable: true,
    id: 'coverage',
    icon: 'heart-coverage',
    relativePath: 'tu-cobertura',
  },
  {
    label: 'Cartilla medica',
    active: true,
    navegable: true,
    id: 'medical-coverage',
    icon: 'medical-coverage',
    relativePath: 'cartilla-medica',
  },
  {
    label: 'Estado de cuenta',
    active: true,
    navegable: true,
    id: 'account-status',
    icon: 'payments',
    relativePath: 'estado-de-cuenta',
  },
  {
    label: 'Tus Autorizaciones',
    active: true,
    id: 'authorizations',
    relativePath: 'tus-autorizaciones',
    navegable: false,
    icon: 'stethoscope',
    children: [
      { id: 'upload-auth', navegable: true, label: 'Cargar pedido', children: [] },
      {
        id: 'get-auth-status',
        navegable: true,
        label: 'Consultar estado',
        relativePath: 'tus-autorizaciones/consultar-estado',
      },
    ],
  },
  {
    label: 'Turnos administrativos',
    navegable: true,
    active: true,
    id: 'turnos',
    icon: 'calendar',
    relativePath: 'turnos-administrativos',
  },
  {
    label: 'Asistencia al viajero',
    navegable: true,
    active: true,
    id: 'travel',
    icon: 'travel',
    relativePath: 'asistencia-al-viajero',
  },
  {
    label: 'Notificaciones',
    navegable: true,
    active: true,
    id: 'notifications',
    icon: 'notifications',
    relativePath: 'notificaciones',
  },
  {
    label: 'Baja de servicio',
    navegable: true,
    active: true,
    id: 'cancellation',
    icon: 'service-cancellation',
    relativePath: 'baja-de-servicio',
  },
];
@Component({
  selector: 'nobis-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  animations: [
    trigger('expandContent', [
      state('collapsed', style({ width: '80px' })),
      state('expanded', style({ width: '336px' })),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideComponent implements OnInit {
  public activatedRoute = '';
  protected isOpen = false;
  protected menuOpen = false;
  protected pages = PAGES;
  private router = inject(Router);
  private facade = inject(HeaderFacade);
  private store = inject(Store);
  private planId$ = this.store.select(selectPlanId);
  protected version!: string;
  private cdr = inject(ChangeDetectorRef);

  public ngOnInit(): void {
    this.getVersionProyect();
    this.toggleMenuSectionsVisibilityByPlanId();
    this.facade.menu
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap((res: any[]) => {
          const index = this.pages.findIndex(page => page.id === 'authorizations');
          this.pages[index].children![0].children = [];

          res?.forEach(element => {
            this.pages[index].children![0].children!.push({
              id: element.id,
              label: element.name,
              navegable: true,
              relativePath: `tus-autorizaciones`,
              fragment: 'id=${element.id}',
            });
          });
        }),
        first(res => !!res)
      )
      .subscribe();

    this.activatedRoute = this.router.url.split('/')[2].split('?')[0];
  }

  protected getVersionProyect() {
    import('../../../../../package.json').then(value => {
      this.version = 'Versión: ' + value.version;
      this.cdr.markForCheck();
    });
  }

  /**
   * lógica para setear los permisos de visualización de secciones
   * */
  private toggleMenuSectionsVisibilityByPlanId() {
    this.planId$
      .pipe(
        first(),
        tap((planId: number) => {
          if (planId === 8) {
            this.deactivateSectionsByPlanLabel(
              ['Tu cobertura', 'Asistencia al viajero', 'Cartilla medica'],
              this.pages
            );
          }
          if (planId === 1) {
            this.deactivateSectionsByPlanLabel(
              ['Tu cobertura', 'Asistencia al viajero'],
              this.pages
            );
          }
        })
      )
      .subscribe();
  }

  private deactivateSectionsByPlanLabel(labelsToDeactivate: string[], pages: PageNode[]): void {
    this.pages = pages.map(page => ({
      ...page,
      active: !labelsToDeactivate.includes(page.label),
    }));
  }

  protected closeMenu(subchildNode: any) {
    this.facade.defaultLabelAuthorizations();
    this.facade.labelAuthorizations(subchildNode.id);
    this.facade.activeMenu(subchildNode.label);
  }

  protected handleActivatedPage(item: PageNode, event: Event): void {
    event.stopPropagation();

    if (item.relativePath) {
      const activatedRoute = this.getActivatedRouteRoot(item);

      if (this.activatedRoute !== activatedRoute) {
        this.pages.forEach(page => (page.focused = false));
      }

      this.activatedRoute = activatedRoute;

      if (item.navegable) {
        this.router.navigate([`/dashboard/${item.relativePath}`], {
          queryParams: item.fragment ? { id: item.id } : null,
        });

        this.pages.forEach(page => (page.focused = false));

        this.closeMenu(item);
      }
    }

    if (item.children) {
      item.focused = !item.focused;
    }
  }

  protected toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  private getActivatedRouteRoot(node: PageNode): string {
    if (!node.relativePath) {
      return '';
    }

    const fragmentRoot = node.relativePath.split('/');
    const rootWithoutQueryParams = fragmentRoot[0].split('?');
    return rootWithoutQueryParams[0];
  }
}

enum AuthorizationFragments {
  'practicas-medicas' = 1,
  'medicacion',
  'salud-sexual',
  'adecuacion-genero',
}
