import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { page } from 'src/app/core/utils/pages';
import { HeaderFacade } from '../header.facade';
import { SelectAuthorizations } from 'src/app/store/actions/authorizatios.actions';
import { Store } from '@ngrx/store';
import { selectPlanId } from 'src/app/store/selectors/authentication.selectors';
import { first, tap } from 'rxjs';
@Component({
  selector: 'nobis-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {
  private facade = inject(HeaderFacade);
  private router = inject(Router);
  private store = inject(Store);
  protected nav = page;
  protected isOpen = true;
  protected authorizationOptions = false;
  protected loadOrderOption = false;
  protected menuAuthorizations$ = this.facade.menu;
  private planId$ = this.store.select(selectPlanId);

  ngOnInit(): void {
    this.planId$
      .pipe(
        first(),
        tap((planId: number) => {
          if (planId === 8) {
            this.deleteSectionByPlanId(
              ['Tu cobertura', 'Asistencia al viajero', 'Cartilla medica'],
              this.nav
            );
          }
          if (planId === 1) {
            this.deleteSectionByPlanId(['Tu cobertura', 'Asistencia al viajero'], this.nav);
          }
        })
      )
      .subscribe();
  }

  private deleteSectionByPlanId(section: string[], arrayOriginal: any[]): void {
    section.forEach(elemento => {
      const index = arrayOriginal.findIndex(item => item.label === elemento);
      if (index !== -1) {
        arrayOriginal.splice(index, 1);
      }
    });
  }
  protected handleToggleMenu(): void {
    this.facade.toggleOpenStatus();
  }

  protected menuClose() {
    this.isOpen = false;
  }
  protected actionItem(item: any) {
    if (!item.moreOptions) {
      this.handleToggleMenu();
      this.router.navigate(['/dashboard', item.relativePath]);
    } else {
      this.authorizationOptions = !this.authorizationOptions;
    }
  }
  protected loadOrderAction(option: any) {
    option === 'Consultar estado'
      ? this.redirectAuthorizationHistory()
      : (this.loadOrderOption = !this.loadOrderOption);
  }

  protected redirectAuthorizationHistory() {
    this.router.navigate(['/dashboard/tus-autorizaciones/consultar-estado']), this.handleToggleMenu();
  }
  protected redirectLoadOrderOption(element: SelectAuthorizations) {
    this.router.navigate(['/dashboard/tus-autorizaciones']);
    this.facade.defaultLabelAuthorizations();
    this.facade.activeMenu(element.name);
    this.handleToggleMenu();
    this.facade.labelAuthorizations(element.id);
    this.loadOrderOption = false;
    this.authorizationOptions = false;
  }
}
