<section id="register" [@fadeIn]>
  <h1>REGISTRATE</h1>
  <ng-container *ngIf="stepActive$ | async as stepActive">
    <div class="wrapper-step">
      <ng-container *ngFor="let item of step">
        <div class="step" [ngClass]="{ active: item === stepActive }">{{ item }}</div>
      </ng-container>
    </div>

    <ng-container *ngIf="stepActive === 1; else changedPassword">
      <nobis-form-register></nobis-form-register>
    </ng-container>

    <ng-template #changedPassword>
      <nobis-change-password [nameAction]="'TERMINAR REGISTRO'" ></nobis-change-password>
    </ng-template>
  </ng-container>
</section>
