import { createActionGroup, props } from "@ngrx/store";
import { PlanSummary } from "src/app/core/model/plan.interfaces";

export const planApiActions = createActionGroup({
  source: 'Plan API',
  events: {
    'Plan Request': props<{ planId: number }>(),
    'Plan Success': props<{ plan: PlanSummary  }>(),
    'Plan Failure': props<{ error: string }>(),
  },
});
