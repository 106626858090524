import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlideButtonComponent } from './slide-button.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SlideButtonComponent],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [SlideButtonComponent],
})
export class SlideButtonModule { }
