<section [@fadeIn] class="wrapper">
  <p>
    Te enviamos un correo a {{ userData.email | hiddenEmail }} . Ingresá <br />
    debajo la contraseña temporal que recibiste
    <strong>
      y elegí una nueva <br />
      contraseña</strong
    >
    que te sea fácil de recordar.
  </p>
  <form [formGroup]="form">
    <mat-form-field hideRequiredMarker color="accent" appearance="outline">
      <input
        autocomplete="new-password"
        [type]="hide ? 'password' : 'text'"
        placeholder="Contraseña recibida x mail"
        matInput
        formControlName="previousPassword" />
      <button
        mat-icon-button
        matSuffix
        [color]="
          form.controls['previousPassword'].errors && form.controls['previousPassword'].touched
            ? 'warn'
            : 'accent'
        "
        (click)="togglePasswordVisibility($event)"
        [attr.aria-label]="'Mostrar contraseña'"
        [attr.aria-pressed]="'Ocultar contrasena'">
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>

      <button mat-icon-button matPrefix class="pointer-event-none">
        <mat-icon svgIcon="icon-password"></mat-icon>
      </button>
      <mat-error *ngIf="form.controls['previousPassword'].hasError('required')">
        Este campo es requerido.
      </mat-error>
      <mat-error *ngIf="form.get('previousPassword')?.hasError('incorrect')">
        {{ messengerError }}
      </mat-error>
    </mat-form-field>
    <mat-form-field hideRequiredMarker color="accent" appearance="outline">
      <input
        autocomplete="new-password"
        [type]="hide ? 'password' : 'text'"
        placeholder="Nueva contraseña"
        matInput
        formControlName="updatedPassword" />
      <button
        mat-icon-button
        matSuffix
        [color]="
          form.controls['updatedPassword'].errors && form.controls['updatedPassword'].touched
            ? 'warn'
            : 'accent'
        "
        (click)="togglePasswordVisibility($event)">
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <button mat-icon-button matPrefix class="pointer-event-none">
        <mat-icon svgIcon="icon-password"></mat-icon>
      </button>
      <mat-hint
        >Debe ser de entre 6 y 15 caracteres, con al menos una mayúscula, una minúscula y un
        número.</mat-hint
      >
      <mat-error *ngIf="form.controls['updatedPassword'].hasError('required')">
        Este campo es requerido.
      </mat-error>
    </mat-form-field>
    <mat-form-field hideRequiredMarker color="accent" appearance="outline">
      <input
        placeholder="Repetí nueva contraseña"
        matInput
        autocomplete="new-password"
        [type]="hide ? 'password' : 'text'"
        formControlName="passwordConfirmation" />
      <button
        mat-icon-button
        matSuffix
        [color]="
          form.controls['passwordConfirmation'].errors &&
          form.controls['passwordConfirmation'].touched
            ? 'warn'
            : 'accent'
        "
        (click)="togglePasswordVisibility($event)"
        [attr.aria-label]="'Mostrar contraseña'"
        [attr.aria-pressed]="'Ocultar contrasena'">
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <button mat-icon-button matPrefix>
        <mat-icon svgIcon="icon-password"></mat-icon>
      </button>
      <mat-error *ngIf="form.controls['passwordConfirmation']?.hasError('required')">
        Este campo es requerido.
      </mat-error>
      <mat-error *ngIf="form.hasError('passwordMatch')">No coinciden las contraseñas</mat-error>
    </mat-form-field>
  </form>

  <div class="action">
    <nobis-button [disabled]="form.invalid" (click)="passwordUpdate()">{{
      nameAction
    }}</nobis-button>
  </div>
</section>
