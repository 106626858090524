

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccountStatusService } from 'src/app/core/services/accountStatus.service';
import { accountStatusApiActions } from '../actions/accountStatus.action';

@Injectable()
export class AccountStatusEffects {
    constructor(private actions$: Actions, private service: AccountStatusService) { }
   
    historyAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountStatusApiActions.historyStatementOfAccountRequest),
            switchMap((action: Action & { benId: number }) =>
                this.service.getHistoryStatementOfAccountByBenId(action.benId).pipe(
                    map(payload => accountStatusApiActions.historyStatementOfAccountSuccess({ history: payload })),
                    catchError(() => of(accountStatusApiActions.historyStatementOfAccountFailure({ error: 'Error on history account' })))
                )
            )
        );
    });

    debtAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountStatusApiActions.statementDebtsRequest),
            switchMap((action: Action & { benId: number }) =>
                this.service.getDebtStatementOfAccountByBenId(action.benId).pipe(
                    map(payload => accountStatusApiActions.statementDebtsSuccess({ history: payload })),
                    catchError(() => of(accountStatusApiActions.statementDebtsFailure({ error: 'Error on history account' })))
                )
            )
        );
    });
}