import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthorizationsService } from 'src/app/core/services/authorizations.service';
import { authorizationsApiActions } from '../actions/authorizatios.actions';

@Injectable()
export class AuthorizationsEffects {
  constructor(
    private actions$: Actions,
    private authorizationsService: AuthorizationsService
  ) {}
  authorizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.selectAuthorizationsRequest.type),
      switchMap((action: Action & { id: number }) =>
        this.authorizationsService.getAuthorizationType(action.id).pipe(
          map(payload =>
            authorizationsApiActions.selectAuthorizationsSuccess({ authorizations: payload })
          ),
          catchError(() =>
            of(authorizationsApiActions.selectAuthorizationsFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });
  authorizationsHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.authorizationsHistoryRequest.type),
      switchMap((action: Action & { benId: number }) =>
        this.authorizationsService.getAuthorizationHistory(action.benId).pipe(
          map(payload =>
            authorizationsApiActions.authorizationsHistorySuccess({
              authorizationsHistory: payload,
            })
          ),
          catchError(() =>
            of(authorizationsApiActions.authorizationsHistoryFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });
  authorizationsNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.authorizationsNotificationRequest.type),
      switchMap((action: Action & { benId: number }) =>
        this.authorizationsService.getAuthorizationNotification(action.benId).pipe(
          map(payload =>
            authorizationsApiActions.authorizationsNotificationSuccess({
              authorizationsNotification: payload,
            })
          ),
          catchError(() =>
            of(authorizationsApiActions.authorizationsNotificationFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });
  labelAuthorizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.labelAuthorizationsRequest.type),
      switchMap((action: Action & { id: number }) =>
        this.authorizationsService.getAuthorizationType(action.id).pipe(
          map(payload =>
            authorizationsApiActions.labelAuthorizationsSuccess({ authorizations: payload })
          ),
          catchError(() =>
            of(authorizationsApiActions.labelAuthorizationsFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });

  secondLabelAuthorizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.secondLabelAuthorizationsRequest.type),
      switchMap((action: Action & { id: number }) =>
        this.authorizationsService.getAuthorizationType(action.id).pipe(
          map(payload =>
            authorizationsApiActions.secondLabelAuthorizationsSuccess({ authorizations: payload })
          ),
          catchError(() =>
            of(authorizationsApiActions.secondLabelAuthorizationsFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });

  thirdLabelAuthorizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.thirdLabelAuthorizationsRequest.type),
      switchMap((action: Action & { id: number }) =>
        this.authorizationsService.getAuthorizationType(action.id).pipe(
          map(payload =>
            authorizationsApiActions.thirdLabelAuthorizationsSuccess({ authorizations: payload })
          ),
          catchError(() =>
            of(authorizationsApiActions.thirdLabelAuthorizationsFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });

  firstHelpMessenger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.firstFieldHelpMessageRequest.type),
      switchMap((action: Action & { groupId: number; planId: number; provinceId: number }) =>
        this.authorizationsService
          .getAlertInputAuthorizations(action.groupId, action.planId, action.provinceId)
          .pipe(
            map(payload =>
              authorizationsApiActions.firstFieldHelpMessageSuccess({ notification: payload })
            ),
            catchError(() =>
              of(authorizationsApiActions.firstFieldHelpMessageFailure({ error: 'Error ' }))
            )
          )
      )
    );
  });

  secondtHelpMessenger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.secondFieldHelpMessageRequest.type),
      switchMap((action: Action & { groupId: number; planId: number; provinceId: number }) =>
        this.authorizationsService
          .getAlertInputAuthorizations(action.groupId, action.planId, action.provinceId)
          .pipe(
            map(payload =>
              authorizationsApiActions.secondFieldHelpMessageSuccess({ notification: payload })
            ),
            catchError(() =>
              of(authorizationsApiActions.secondFieldHelpMessageFailure({ error: 'Error ' }))
            )
          )
      )
    );
  });

  thirdtHelpMessenger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.thirdFieldHelpMessageRequest),
      switchMap((action: Action & { groupId: number; planId: number; provinceId: number }) =>
        this.authorizationsService
          .getAlertInputAuthorizations(action.groupId, action.planId, action.provinceId)
          .pipe(
            map(payload =>
              authorizationsApiActions.thirdFieldHelpMessageSuccess({ notification: payload })
            ),
            catchError(() =>
              of(authorizationsApiActions.thirdFieldHelpMessageFailure({ error: 'Error ' }))
            )
          )
      )
    );
  });

  formData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authorizationsApiActions.generateAuthorizationFormRequest.type),
      switchMap((action: Action & { groupId: number }) =>
        this.authorizationsService.getAuthorizationForm(action.groupId).pipe(
          map(payload =>
            authorizationsApiActions.generateAuthorizationFormSuccess({ dataForm: payload })
          ),
          catchError(() =>
            of(authorizationsApiActions.generateAuthorizationFormFailure({ error: 'Error ' }))
          )
        )
      )
    );
  });
}
