<form [@fadeIn] [formGroup]="form">
  <nobis-loading *ngIf="loading"></nobis-loading>
  <mat-form-field color="accent" hideRequiredMarker appearance="outline">
    <button
      class="pointer-event-none"
      mat-icon-button
      matPrefix
      [attr.aria-label]="' Selecciona un tipo de documento'">
      <mat-icon svgIcon="dni"></mat-icon>
    </button>
    <mat-select formControlName="docType" placeholder="Tipo de documento">
      <ng-container *ngIf="typesDocument$ | async as typesDocuments">
        <mat-option
          *ngFor="let item of typesDocuments"
          (click)="typeSelected(item.value)"
          [value]="item.id">
          {{ item.value }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="form.controls['docType'].hasError('required')"
      >Selecioná un tipo de Documento</mat-error
    >
  </mat-form-field>

  <mat-form-field color="accent" hideRequiredMarker appearance="outline">
    <input
      matInput
      type="number"
      pattern="[0-9]*"
      class="pointer-event-none"
      formControlName="docNumber"
      placeholder="Nº de {{ type }}" />
    <button
      class="pointer-event-none"
      mat-icon-button
      matPrefix
      [attr.aria-label]="'ingresa tu documento'">
      <mat-icon svgIcon="123"></mat-icon>
    </button>
    <mat-error *ngIf="form.controls['docNumber']?.hasError('pattern')"
      >Ingresa solo números</mat-error
    >
    <mat-error *ngIf="form.controls['docNumber']?.hasError('required')"
      >Este campo es requerido</mat-error
    >
  </mat-form-field>

  <mat-form-field color="accent" hideRequiredMarker appearance="outline">
    <input
      matInput
      type="email"
      class="pointer-event-none"
      formControlName="email"
      placeholder="E-mail" />
    <button
      class="pointer-event-none"
      mat-icon-button
      matPrefix
      [attr.aria-label]="'ingresa tu email'">
      <mat-icon class="material-symbols-outlined">mail</mat-icon>
    </button>
    <mat-hint>Usar el e-mail del proceso de afiliación.</mat-hint>
    <mat-error *ngIf="form.get('docNumber')?.hasError('errorDocument')">
      {{ errorDocument }}
    </mat-error>
    <mat-error *ngIf="form.get('email')?.hasError('incorrect')">
      {{ errorMessage}}
    </mat-error>
    <mat-error *ngIf="form.controls['email'].hasError('required')">El email es requerido</mat-error>
    <mat-error *ngIf="form.controls['email'].hasError('email')">El email no es válido</mat-error>
  </mat-form-field>

  <mat-form-field color="accent" hideRequiredMarker appearance="outline">
    <input
      matInput
      type="email"
      class="pointer-event-none"
      formControlName="emailConfirmation"
      placeholder="Repetir e-mail" />
    <button
      class="pointer-event-none"
      mat-icon-button
      matPrefix
      [attr.aria-label]="'Repetir e-mail'">
      <mat-icon class="material-symbols-outlined">mail</mat-icon>
    </button>

    <mat-error *ngIf="form.hasError('emailMismatch')">No coinciden los email</mat-error>
    <mat-error *ngIf="form.controls['emailConfirmation'].hasError('required')"
      >Ingresá nuevamente tu email</mat-error
    >
  </mat-form-field>

  <div class="action">
    <a [routerLink]="['login']"> <mat-icon>chevron_left</mat-icon> VOLVER</a>
    <nobis-button [disabled]="form.invalid" (click)="register()">CONTINUAR</nobis-button>
  </div>
</form>
