import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { affiliateApiActions } from 'src/app/store/actions/affiliate.action';
import { familyGroupApiActions } from 'src/app/store/actions/familyGroup.actions';
import { selectUserBenGrId } from 'src/app/store/selectors/authentication.selectors';


@Injectable()
export class MyFamilyGroupFacade {
    private store = inject(Store);
    private benGrId = this.store.select(selectUserBenGrId)

    public dispachFamilyGroup(): void {
        this.benGrId.pipe(
            tap((benGrId: number) => this.store.dispatch(familyGroupApiActions.familyGroupRequest({ benGrId }))
            )).subscribe()
    }
    public dispachSelectFamilyMember(benId: number): void {
     this.store.dispatch(affiliateApiActions.affiliateRequest({ benId }))
    }
}

export enum FamilyGroupRelationship {
    TITULAR,
    CONYUGE,
    HIJO
}