import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { TooltipNavModule } from '../tooltip/tooltip.module';
import { AsideComponent } from './aside.component';


@NgModule({
    declarations: [AsideComponent],
    imports: [CommonModule, MaterialModule, RouterModule, TooltipNavModule],
    exports: [AsideComponent],
})
export class AsideModule {
}

