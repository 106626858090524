import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { authorizationsApiActions } from 'src/app/store/actions/authorizatios.actions';
import { selectPlanId, selectProvinceId } from 'src/app/store/selectors/authentication.selectors';
import { selectauthorizationsMenu } from 'src/app/store/selectors/authorizations.selectors';

@Injectable({
    providedIn: 'root'
})
export class HeaderFacade {
    private store = inject(Store);
    private isOpen$ = new BehaviorSubject<boolean>(false);
    public menu = this.store.select(selectauthorizationsMenu)
    public planId = this.store.select(selectPlanId)
    public provinceId = this.store.select(selectProvinceId)

    public activeMenu(active: string) {
        this.store.dispatch(authorizationsApiActions.authorizationActive({ active }))
    }

    public getHelpMessengerForInput(groupId: number, planId: number, provinceId: number) {
        this.store.dispatch(authorizationsApiActions.firstFieldHelpMessageRequest({ groupId, planId, provinceId }))
    }

    public defaultLabelAuthorizations() {
        this.store.dispatch(authorizationsApiActions.defaultLabelsAuthorizations())
    }
    public labelAuthorizations(id: number) {
        this.store.dispatch(authorizationsApiActions.labelAuthorizationsRequest({ id }))
    }

    public get isOpen(): boolean {
        return this.isOpen$.value;
    }

    public set isOpen(value: boolean) {
        this.isOpen$.next(value);
    }

    public get isOpenChanges(): Observable<boolean> {
        return this.isOpen$.asObservable();
    }

    public toggleOpenStatus(): void {
        this.isOpen$.next(!this.isOpen);
    }


}