import { createActionGroup, props } from "@ngrx/store";
import { Affiliate } from "src/app/core/model/affiliate.interfaces";

export const affiliateApiActions = createActionGroup({
    source: 'Affiliate API',
    events: {
      'Affiliate Request': props<{ benId: number }>(),
      'Affiliate Success': props<{ affiliate: Affiliate[]}>(),
      'Affiliate Failure': props<{ error: string }>(),
    },
  });
  