import { CommonModule } from '@angular/common';
import { NgModule, forwardRef } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { InputTypeFileComponent } from './input-type-file.component';
import { RequiredElementModule } from '../required-element/required-element.module';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PipesModule } from 'src/app/core/utils/pipe/pipe.module';

@NgModule({
  declarations: [InputTypeFileComponent],
  imports: [CommonModule, MaterialModule, RequiredElementModule, PipesModule],
  exports: [InputTypeFileComponent],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTypeFileComponent),
      multi: true,
    },
  ],
})
export class InputTypeFileModule {}
