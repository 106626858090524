import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class AccountStatusService {
  private httpClient = inject(HttpClient);

  public getHistoryStatementOfAccountByBenId(benId: number): Observable<InvoiceData[]> {
    const url = `${environment.apiUrl}affiliates/${benId}/statement/history`
    return this.httpClient.get<Response<InvoiceData[]>>(url).pipe(
      map(({ data }) => data)
    )
  }

  public getDebtStatementOfAccountByBenId(benId: number): Observable<Amount[]> {
    const url = `${environment.apiUrl}affiliates/${benId}/statement`
    return this.httpClient.get<Response<Amount[]>>(url).pipe(
      map(({ data }) => data)
    )
  }

  public paymentByMercadopago(payload: Transaction): Observable<Data> {
    const url = `${environment.apiUrl}payments/preference`
    return this.httpClient.post<Response<Data>>(url, payload).pipe(
      map(({ data }) => data)
    )
  }

  public generateInvoiceDocument(benId: number, compId: number, token: string): Observable<any> {
    const url = `${environment.apiUrl}affiliates/${benId}/statement/receipt/${compId}/${token}`
    return this.httpClient.get<Response<any>>(url, { responseType: 'text' as any })
  }
}


export interface Transaction {
  benId: number;
  compId: number;
  amount: number;
  period: number;
}

export interface InvoiceData {
  compId: number;
  compNro: number;
  status: string;
  period: number;
  periodString: string;
  dateTime: string;
  amount: number;
  net: number;
  date: string;
  duedate: string;
  name: string;
}

export interface Amount {
  amount: number;
  compId: number;
  compNro: number;
  date: string;
  dateTime: string;
  duedate: string;
  duration: { days: number };
  days: number;
  name: string;
  net: number;
  payment: boolean;
  paymentMethod: string;
  period: number;
  periodString: string;
  status: string;
}

export interface Payer {
  phone: {
    area_code: string;
    number: string;
  };
  address: {
    zip_code: string;
    street_name: string;
    street_number: number | null;
  };
}

export interface Item {
  id: string;
  category_id: string;
  currency_id: string;
  description: string;

}

export interface BackUrls {
  failure: string;
}

interface RedirectUrls {
  failure: string;
  pending: string;
  success: string;
}

export interface Shipment {
  default_shipping_method: string | null;
}

export interface Data {
  additional_info: string;
  auto_return: string;
  back_urls: BackUrls;
  binary_mode: boolean;
  client_id: string;
  collector_id: number;
  coupon_code: string | null;
  coupon_labels: string[] | null;
  date_created: string;
  date_of_expiration: string | null;
  expiration_date_from: string | null;
  expiration_date_to: string | null;
  expires: boolean;
  external_reference: string;
  id: string;
  init_point: string;
  internal_metadata: any;
  items: Item[];
  last_updated: string | null;
  marketplace: string;
  marketplace_fee: number;
  metadata: any;
  notification_url: string | null;
  operation_type: string;
  payer: Payer;
  payment_methods: {
    default_card_id: string | null;
    default_payment_method_id: string | null;
    excluded_payment_methods: { id: string }[];
  };
  processing_modes: any;
  product_id: string | null;
  redirect_urls: RedirectUrls;
  sandbox_init_point: string;
  shipments: Shipment;
  site_id: string;
  total_amount: number;
}