import { createActionGroup, props } from '@ngrx/store';
import { Category } from 'src/app/core/model/plan.interfaces';



export const categoriesApiActions = createActionGroup({
  source: 'Categories API',
  events: {
    'Categories Request': props,
    'Categories Success': props<{ categories: Category[] }>(),
    'Categories Failure': props<{ error: string }>(),
    'Get Category Detail Request': props<{ planId: number, categoryId: number, }>(),
    'Get Category Detail Success': props<{ data: any, categoryId: number }>(),
    'Get Category Detail Failure': props,
    'Set Category': props<{ category: Category & { isOpen: boolean } }>()
  },
});


