<div class="wrapper" [ngClass]="{ invalid: inputInvalid() }">
  <mat-form-field [appearance]="'outline'">
    <input matInput placeholder="{{ label }}" />
    <button mat-icon-button matSuffix [color]="'accent'" (click)="togglePasswordVisibility($event)">
      <mat-icon [svgIcon]="'clip'"></mat-icon>
    </button>
    <mat-hint [innerHTML]="hint"></mat-hint>
  </mat-form-field>
  <input
    enctype="multipart/form-data"
    #fileInput
    type="file"
    [accept]="accept"
    [multiple]="multi"
    (change)="onFileSelected($event)" />
</div>

<div *ngFor="let file of selectedFiles; let i = index">
  <div class="file">
    <a (click)="downloadDocument(file)"> {{ file.name | truncateText: 35}}</a>
    <mat-icon [svgIcon]="'delete'" (click)="removeFile(i)"></mat-icon>
  </div>
</div>
