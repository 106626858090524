import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { DashboardFacade } from './dashboard.facade';
import { MyFamilyGroupFacade } from './dashboard-my-family-group/my-family-group.facade';


@Component({
  selector: 'nobis-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private router = inject(Router)
  private facade = inject(DashboardFacade);
  private facadeMyFamilyGroup= inject(MyFamilyGroupFacade)
  private store = inject(Store)


  public ngOnInit(): void {
    this.facade.isAuthenticated
      .pipe(finalize(() => this.router.navigateByUrl('login')))
      .subscribe();
    this.facade.dispatchAuthorizations(0)
    this.facadeMyFamilyGroup.dispachFamilyGroup();
  }
}



