import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { TruncateTextPipe } from './min-text.pipe';
import { HiddenEmailPipe } from './hidden-email.pipe';

@NgModule({
  declarations: [ReplaceLineBreaksPipe, TruncateTextPipe, HiddenEmailPipe],
  imports: [CommonModule],
  exports: [ReplaceLineBreaksPipe, TruncateTextPipe, HiddenEmailPipe],
})
export class PipesModule {}
