import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangePasswordComponent } from './change-password.component';
import { PipesModule } from 'src/app/core/utils/pipe/pipe.module';


@NgModule({
    declarations: [ChangePasswordComponent],
    imports: [CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        PipesModule
    ],
    exports: [ChangePasswordComponent],
   
})
export class ChangePasswordModule { }