import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { authApiActions } from '../actions/authentication.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationEffects {
  constructor(private actions$: Actions, private authenticationService: AuthenticationService, private router: Router) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.loginRequest.type),
      switchMap((action: Action & { docType: number; docNumber: number; password: string }) =>
        this.authenticationService.login({ docType: action.docType, password: action.password, docNumber: action.docNumber }).pipe(
          map(payload => authApiActions.loginSuccess({ user: payload })),
          catchError(error => {
            const response = error.error;
            return of(authApiActions.loginFailure({ error: response }));
          })
        )
      )
    );
  });
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authApiActions.logout.type),
      map(() => {
        this.router.navigateByUrl('');
        return authApiActions.logoutSuccess();
      }
      ))
  }
  );
}


