import { Component, Input } from '@angular/core';

@Component({
    selector: 'nobis-slide-button',
    templateUrl: './slide-button.component.html',
    styleUrls: ['./slide-button.component.scss'],
})
export class SlideButtonComponent {
    @Input() public isTrue = false;


    protected changeState(): void {
        this.isTrue = !this.isTrue;

    }
}