import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { ButtonStateComponent } from './button-state.component';



@NgModule({
  declarations: [
    ButtonStateComponent
  ],
  imports: [
    CommonModule, MaterialModule
  ],
  exports: [ButtonStateComponent]
})
export class ButtonStateModule { }
