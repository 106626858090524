import { createActionGroup, props } from '@ngrx/store';
import { FormElement } from 'src/app/core/services/authorizations.service';
import { Group, Input } from '../reducers/authorizations.reducers';

export const authorizationsApiActions = createActionGroup({
  source: 'authorizations API',
  events: {
    'Select Authorizations Request': props<{ id: number }>(),
    'Select Authorizations Success': props<{ authorizations: SelectAuthorizations[] }>(),
    'Select Authorizations Failure': props<{ error: string }>(),

    'Authorization Active': props<{ active: string }>(),

    'Label Authorizations Request': props<{ id: number }>(),
    'Label Authorizations Success': props<{ authorizations: SelectAuthorizations[] }>(),
    'Label Authorizations Failure': props<{ error: string }>(),

    'Second Label Authorizations Request': props<{ id: number }>(),
    'Second Label Authorizations Success': props<{ authorizations: SelectAuthorizations[] }>(),
    'Second Label Authorizations Failure': props<{ error: string }>(),

    'third Label Authorizations Request': props<{ id: number }>(),
    'third Label Authorizations Success': props<{ authorizations: SelectAuthorizations[] }>(),
    'third Label Authorizations Failure': props<{ error: string }>(),

    'first field help message request': props<{
      groupId: number;
      planId: number;
      provinceId: number;
    }>(),
    'first field help message Success': props<{ notification: Notification }>(),
    'first field help message Failure': props<{ error: string }>(),

    'Second field help message request': props<{
      groupId: number;
      planId: number;
      provinceId: number;
    }>(),
    'Second field help message Success': props<{ notification: Notification }>(),
    'Second field help message Failure': props<{ error: string }>(),

    'third  field help message request': props<{
      groupId: number;
      planId: number;
      provinceId: number;
    }>(),
    'third  field help message Success': props<{ notification: Notification }>(),
    'third  field help message Failure': props<{ error: string }>(),

    'Generate  Authorization Form Request': props<{ groupId: number }>(),
    'Generate  Authorization Form Success': props<{ dataForm: FormElement[] }>(),
    'Generate  Authorization Form Failure': props<{ error: string }>(),

    'Save Form Data Groups': props<{ token: string; groups: Group[] }>(),

    'Save Form Data Inputs': props<{ input: Input[]; files: Input[] }>(),

    'Save Form Data Ben Id': props<{ benId: number }>(),

    'init Form': props<any>(),

    'Default Labels Authorizations': props<any>(),

    // Historial de autorizaciones
    ' Authorizations History Request': props<{ benId: number }>(),
    ' Authorizations History Success': props<{ authorizationsHistory: any[] }>(),
    ' Authorizations History Failure': props<{ error: string }>(),
    
    //notificaciones de autorizaciones
    ' Authorizations Notification Request': props<{ benId: number }>(),
    ' Authorizations Notification Success': props<{ authorizationsNotification: any[] }>(),
    ' Authorizations Notification Failure': props<{ error: string }>(),
  },
});

export interface SelectAuthorizations {
  children: boolean;
  code: string;
  id: number;
  name: string;
}

export interface Notification {
  id: number;
  name: string;
  icon: string;
  created_at: string;
  updated_at: string;
}
