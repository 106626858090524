<div class="required">
  <svg
    [style.fill]="getColor(status)"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none">
    <mask
      id="path-1-outside-1_2082_6219"
      maskUnits="userSpaceOnUse"
      x="-1"
      y="0"
      width="24"
      height="22"
      fill="black">
      <rect fill="white" x="-1" width="24" height="22" />
      <path d="M11 11V2ZM11 11L2 8.5ZM11 11L20 8.5ZM11 11L17 19.5ZM11 11L5 19.5Z" />
    </mask>
    <path d="M11 11V2ZM11 11L2 8.5ZM11 11L20 8.5ZM11 11L17 19.5ZM11 11L5 19.5Z" fill="#D9D9D9" />
    <path
      d="M13 2C13 0.895431 12.1046 0 11 0C9.89543 0 9 0.895431 9 2H13ZM2.53529 6.57296C1.47102 6.27733 0.368595 6.90044 0.0729642 7.96471C-0.222667 9.02898 0.40044 10.1314 1.46471 10.427L2.53529 6.57296ZM20.5353 10.427C21.5996 10.1314 22.2227 9.02898 21.927 7.96471C21.6314 6.90044 20.529 6.27733 19.4647 6.57296L20.5353 10.427ZM15.3661 20.6534C16.0031 21.5558 17.251 21.7709 18.1534 21.1339C19.0558 20.4969 19.2709 19.249 18.6339 18.3466L15.3661 20.6534ZM3.36606 18.3466C2.72908 19.249 2.94424 20.4969 3.84663 21.1339C4.74903 21.7709 5.99695 21.5558 6.63394 20.6534L3.36606 18.3466ZM13 11V2H9V11H13ZM11.5353 9.07296L2.53529 6.57296L1.46471 10.427L10.4647 12.927L11.5353 9.07296ZM11.5353 12.927L20.5353 10.427L19.4647 6.57296L10.4647 9.07296L11.5353 12.927ZM9.36606 12.1534L15.3661 20.6534L18.6339 18.3466L12.6339 9.84663L9.36606 12.1534ZM9.36606 9.84663L3.36606 18.3466L6.63394 20.6534L12.6339 12.1534L9.36606 9.84663Z"
      mask="url(#path-1-outside-1_2082_6219)" />
  </svg>
  <p *ngIf="status === 1" class="warn">OBLIGATORIO</p>
  <p *ngIf="status === 0" class="success">RECOMENDABLE</p>
</div>
