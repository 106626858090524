<div
  class="wrapper"
  [@expandContent]="isOpen ? 'expanded' : 'collapsed'"
  [ngClass]="{ 'menu-open': isOpen }">
  <!-- Icon Toggle -->
  <div class="collapser">
    <button (click)="toggleMenu()">
      <ng-container *ngIf="isOpen; else close">
        <mat-icon svgIcon="open"> </mat-icon>
      </ng-container>

      <ng-template #close>
        <mat-icon svgIcon="close"> </mat-icon>
      </ng-template>
    </button>
  </div>

  <!-- Navigation -->
  <div>
    <nav [ngClass]="{ 'menu-extended': isOpen }">
      <ng-container *ngFor="let item of pages">
        <ng-container *ngIf="item.active">
          <!-- Page -->
          <a
            class="item"
            [ngClass]="{ active: item.relativePath === activatedRoute }"
            (click)="handleActivatedPage(item, $event)">
            <div class="bg-icon">
              <mat-icon [svgIcon]="item.icon ?? ''"></mat-icon>
              <p>{{ item.label | uppercase }}</p>
              <div *ngIf="!isOpen" class="border"></div>
              <ng-container
                *ngIf="!isOpen"
                [ngTemplateOutlet]="pageNode"
                [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            </div>

            <!-- Tooltip -->
            <nobis-tooltip class="tooltip" *ngIf="!item.focused">{{ item.label }}</nobis-tooltip>
          </a>

          <div *ngIf="isOpen">
            <ng-container
              [ngTemplateOutlet]="menuextendedPageNode"
              [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </nav>
  </div>
  <span>{{ version }}</span>
</div>

<ng-template #pageNode let-item>
  <div class="page-node" [ngClass]="{ open: item.focused }">
    <header>
      <p>{{ item.label | uppercase }}</p>
    </header>

    <div class="menu">
      <ng-container *ngFor="let childNode of item.children">
        <div class="action" (click)="handleActivatedPage(childNode, $event)">
          <p>{{ childNode.label | uppercase }}</p>
          <mat-icon *ngIf="childNode.children?.length">
            {{ childNode.focused ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </div>
        <!-- Nodos nietos -->
        <ng-container>
          <ul *ngIf="childNode.children?.length && childNode.focused">
            <li
              *ngFor="let subchildNode of childNode.children"
              (click)="handleActivatedPage(subchildNode, $event)">
              {{ subchildNode.label }}
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #menuextendedPageNode let-item>
  <div class="menu">
    <ng-container *ngFor="let page of item.children">
      <div class="action" (click)="handleActivatedPage(page, $event)">
        <p>{{ page.label | uppercase }}</p>
        <mat-icon [ngClass]="{ visibility: !page.children?.length }">
          {{ page.focused ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>
      <ul *ngIf="page.focused">
        <li
          (click)="closeMenu(item); toggleMenu();"
          class="padding-left"
          *ngFor="let item of page.children">
          {{ item.label }}
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>
