import { createReducer, on } from '@ngrx/store';
import { FormElement } from 'src/app/core/services/authorizations.service';
import { authorizationsApiActions } from '../actions/authorizatios.actions';

export interface FormDataRequest {
  benId: number;
  files: Input[];
  groups: Group[];
  inputs: Input[];
  token: string;
}
export interface AuthorizationsState {
  isLoading: boolean;
  error: boolean;
  authorizationsType?: any;
  active?: any;
  labelAuthorizations?: any;
  secondLabelAuthorizations?: any;
  thirdLabelAuthorizations?: any;
  form?: { form: FormElement[]; loading: boolean; error?: any };
  formData: Partial<FormDataRequest>;
  authorizationsHistory?: any;
  authorizationsNotification?: any;
}

export const initialState: AuthorizationsState = {
  isLoading: false,
  error: false,
  formData: {},
};

export const authorizationsReducer = createReducer(
  initialState,
  on(
    authorizationsApiActions.authorizationsHistoryRequest,
    (state): AuthorizationsState => ({
      ...state,
      authorizationsHistory: { isLoading: true },
    })
  ),
  on(
    authorizationsApiActions.authorizationsHistorySuccess,
    (state, payload): AuthorizationsState => ({
      ...state,
      authorizationsHistory: {
        ...payload,
        isLoading: false,
      },
    })
  ),
  on(
    authorizationsApiActions.authorizationsHistoryFailure,
    (state): AuthorizationsState => ({
      ...state,
      authorizationsHistory: { isLoading: false },
    })
  ),
  on(
    authorizationsApiActions.authorizationsNotificationSuccess,
    (state, authorizationsNotification): AuthorizationsState => ({
      ...state,
      authorizationsNotification,
    })
  ),
  on(
    authorizationsApiActions.selectAuthorizationsRequest,
    (state): AuthorizationsState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    authorizationsApiActions.selectAuthorizationsSuccess,
    (state, payload): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: false,
      authorizationsType: payload,
    })
  ),

  on(
    authorizationsApiActions.selectAuthorizationsFailure,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    authorizationsApiActions.authorizationActive,
    (state, active): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: false,
      active,
    })
  ),
  on(
    authorizationsApiActions.labelAuthorizationsRequest,
    (state): AuthorizationsState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    authorizationsApiActions.labelAuthorizationsSuccess,
    (state, labelAuthorizations): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: false,
      labelAuthorizations,
      secondLabelAuthorizations: '',
      thirdLabelAuthorizations: '',
    })
  ),
  on(
    authorizationsApiActions.labelAuthorizationsFailure,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    authorizationsApiActions.secondLabelAuthorizationsRequest,
    (state): AuthorizationsState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    authorizationsApiActions.secondLabelAuthorizationsSuccess,
    (state, secondLabelAuthorizations): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: false,
      secondLabelAuthorizations,
    })
  ),
  on(
    authorizationsApiActions.secondLabelAuthorizationsFailure,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    authorizationsApiActions.thirdLabelAuthorizationsRequest,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: true,
      error: false,
    })
  ),
  on(
    authorizationsApiActions.thirdLabelAuthorizationsSuccess,
    (state, thirdLabelAuthorizations): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: false,
      thirdLabelAuthorizations,
    })
  ),
  on(
    authorizationsApiActions.thirdLabelAuthorizationsFailure,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),
  on(
    authorizationsApiActions.firstFieldHelpMessageRequest,
    (state, payload): AuthorizationsState => ({
      ...state,
      isLoading: true,
      error: false,
      labelAuthorizations: { ...state.labelAuthorizations, payload },
    })
  ),
  on(
    authorizationsApiActions.firstFieldHelpMessageSuccess,
    (state, payload): AuthorizationsState => ({
      ...state,
      isLoading: true,
      error: false,
      labelAuthorizations: { ...state.labelAuthorizations, payload },
    })
  ),
  on(
    authorizationsApiActions.secondFieldHelpMessageRequest,
    (state, payload): AuthorizationsState => ({
      ...state,
      isLoading: true,
      error: false,
      secondLabelAuthorizations: { ...state.secondLabelAuthorizations, payload },
    })
  ),
  on(
    authorizationsApiActions.thirdFieldHelpMessageSuccess,
    (state, payload): AuthorizationsState => ({
      ...state,
      isLoading: true,
      error: false,
      thirdLabelAuthorizations: { ...state.thirdLabelAuthorizations, payload },
    })
  ),
  on(
    authorizationsApiActions.defaultLabelsAuthorizations,
    (state): AuthorizationsState => ({
      ...state,
      isLoading: false,
      error: true,
      labelAuthorizations: null,
      secondLabelAuthorizations: null,
    })
  ),
  on(
    authorizationsApiActions.generateAuthorizationFormRequest,
    (state): AuthorizationsState => ({
      ...state,
      form: {
        form: [],
        loading: true,
      },
    })
  ),
  on(
    authorizationsApiActions.generateAuthorizationFormSuccess,
    (state, payload): AuthorizationsState => {
      return {
        ...state,
        form: {
          loading: false,
          form: payload.dataForm,
        },
      };
    }
  ),
  on(
    authorizationsApiActions.generateAuthorizationFormFailure,
    (state, error): AuthorizationsState => ({
      ...state,
      form: {
        form: [],
        loading: false,
        error,
      },
    })
  ),
  on(authorizationsApiActions.saveFormDataGroups, (state, { token, groups }) => ({
    ...state,
    formData: {
      token,
      groups,
    },
  })),
  on(authorizationsApiActions.saveFormDataBenId, (state, { benId }) => ({
    ...state,
    formData: {
      ...state.formData,
      benId: benId,
    },
  })),
  on(authorizationsApiActions.saveFormDataInputs, (state, data) => ({
    ...state,
    formData: {
      ...state.formData,
      inputs: data.input,
      files: data.files,
    },
  })),
  on(authorizationsApiActions.initForm, state => ({
    ...state,
    formData: {},
  }))
);

export const authorizationsFeatureKey = 'authorizations';

export interface Group {
  id: number;
  name: string;
}

export interface Input {
  name: string;
  type: string;
  isRequired: boolean;
  value: string;
}

export interface FormData {
  benId?: number;
  token?: string;
  groups?: Group[];
  inputs?: Input[];
}
