import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RegisterFacade } from './register.facade';


@Component({
    selector: 'nobis-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: [
        trigger('fadeIn', [
          transition(':enter', [
            query(':self', [style({ opacity: 0 }), stagger(100, animate('500ms ease-out', style({ opacity: 1 })))])
          ])
        ])
      ]
})
export class RegisterComponent {
    protected facade = inject(RegisterFacade)
    protected stepActive$: Observable<number> = this.facade.stepActive.asObservable()
    protected step: number[] = [1, 2]

}



