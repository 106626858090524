import { createReducer, on } from '@ngrx/store';

import { authApiActions } from '../actions/authentication.actions';
import { accountStatusApiActions } from '../actions/accountStatus.action';

export interface AccountStatusState {
    history?: any;
    debt?:any

}

export const initialState: AccountStatusState = {};

export const accountStatusReducer = createReducer(
    initialState,
    on(
        accountStatusApiActions.historyStatementOfAccountRequest,
        (state): AccountStatusState => ({ ...state, history: { loading: true } })
    ),
    on(
        accountStatusApiActions.historyStatementOfAccountSuccess,
        (state, history): AccountStatusState => ({ ...state, history: { loading: false, history } })
    ),
    on(
        accountStatusApiActions.historyStatementOfAccountFailure,
        (state): AccountStatusState => ({ ...state, history: { loading: false, Error } })
    ),

    
    on(
        accountStatusApiActions.statementDebtsRequest,
        (state): AccountStatusState => ({ ...state, debt: { loading: true } })
    ),
    on(
        accountStatusApiActions.statementDebtsSuccess,
        (state, history): AccountStatusState => ({ ...state, debt: { loading: false, history: history } })
    ),
    on(
        accountStatusApiActions.statementDebtsFailure,
        (state): AccountStatusState => ({ ...state, debt: { loading: false, Error } })
    ),
);


export const accountStatusFeatureKey = 'accountStatus';