import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PlanService } from 'src/app/core/services/plan.service';
import { planApiActions } from '../actions/plan.action';

@Injectable()
export class PlanEffects {
  constructor(private actions$: Actions, private planService: PlanService) { }


  planSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planApiActions.planRequest.type),
      switchMap((action: Action & { planId: number }) =>
        this.planService.getPlanSummary(action.planId).pipe(
          map(payload => planApiActions.planSuccess({ plan: payload })),
          catchError(() => of(planApiActions.planFailure({ error: 'Error on plan' })))
        )
      )
    );
  });
}
