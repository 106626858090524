import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    public isFileTypeAcceptable(fileTypes: string[], targetFileType: string): boolean {
        return (fileTypes.some(acceptable => acceptable === targetFileType))
    }
    /** 
     * @param fileSizeAcceptable expressed in kb
     */
    public isFileSizeAcceptable(fileSizeAcceptable: number, targetFileSize: number): boolean {
        const bytesToKylobytesFactor = 1000;
        return targetFileSize / bytesToKylobytesFactor <= fileSizeAcceptable;
    }

    public downloadFile(file: File, name: string, type: string): void {
        const reader = new FileReader();

        reader.onloadend = () => {
            this.downloadBase64(reader.result as string, name, type)
        };

        reader.readAsDataURL(file);
    }

    public fileToBase64(file: File): Observable<string> {
        return new Observable((observer) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const res = reader.result as string;
                const resBase64 = res.split(',')[1]
                observer.next(resBase64);
                observer.complete();
            };

            reader.onerror = (error) => {
                observer.error(error);
            };
        });
    }


    public downloadBase64(base64: string, fileName: string, type: string): void {
        const splittedBase64 = base64.split(',');
        const data: Uint8Array = new Uint8Array(atob(splittedBase64[1]).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([data], { type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    }
}