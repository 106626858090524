import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject, catchError } from 'rxjs';
import { DocumentService } from 'src/app/core/services/documentType.service';
import { RegisterService } from 'src/app/core/services/register.service';
import { RegisterFacade } from '../register.facade';



@Component({
    selector: 'nobis-form-register',
    templateUrl: './form-register.component.html',
    styleUrls: ['./form-register.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                query(':self', [style({ opacity: 0 }), stagger(100, animate('500ms ease-out', style({ opacity: 1 })))])
            ])
        ])
    ]
})
export class FormRegisterComponent implements OnInit, OnDestroy {
    private fb = inject(FormBuilder);
    private service = inject(RegisterService);
    private facade = inject(RegisterFacade);
    private documentService = inject(DocumentService);
    private destroy$ = new Subject<void>();

    protected hide = true;
    protected typesDocument$!: Observable<any>;
    protected errorMessage!: string;
    protected errorDocument!: string;
    protected loading!: boolean;
    protected form!: FormGroup;
    protected $registrerError!: Observable<boolean>;
    protected type = 'DNI'
    protected typeSelected(type: string) {
        this.type = type
    }

    public ngOnInit(): void {
        localStorage.removeItem('User')
        this.typesDocument$ = this.documentService.getDocumentType()
        this.formInit()
    }
    protected emailsMatch(): boolean {
        const email = this.form.get('email')?.value;
        const repeatEmail = this.form.get('emailConfirmation')?.value;
        return email === repeatEmail;
    }

    protected formInit() {
        this.form = this.fb.group(
            {
                docType: [2, [Validators.required]],
                docNumber: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                emailConfirmation: ['', [Validators.required, Validators.email]],
            },
            {
                validators: emailMatchValidator(),
            }
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected togglePasswordVisibility(event: Event): void {
        event?.stopPropagation();
        this.hide = !this.hide;
    }
    protected saveLocalStore(payload: any): void {
        localStorage.setItem('User', JSON.stringify(payload));

    }

    protected register() {
        this.loading = true;
        this.service.registerAccount(this.form.getRawValue()).pipe(
            catchError((error: any) => {
                this.loading = false;
                const code = error.error.code
                switch (code) {
                    case 422:
                        if (error.error.message.errors.length) {
                            const errors = error.error.message.errors
                            errors.map((error: any) => {
                                if (error.field === 'docNumber') {
                                    this.form.get('docNumber')?.setErrors({ errorDocument: true });
                                    this.errorDocument = error.message

                                }
                                if (error.field === 'email') {
                                    this.form.get('email')?.setErrors({ incorrect: true });
                                    this.errorMessage = error.message

                                }
                            })
                        } else {
                            this.form.get('email')?.setErrors({ incorrect: true });
                            this.errorMessage = error.message
                        }
                        break;
                    case 404:
                        this.form.get('email')?.setErrors({ incorrect: true });
                        this.errorMessage = error.error.message.errors
                        break;
                    case 400:
                        this.form.get('email')?.setErrors({ incorrect: true });
                        this.errorMessage = error.error.message.errors
                        break;
                    default:
                        break;
                }

                return error
            })
        ).subscribe(() => {
            this.saveLocalStore(this.form.getRawValue())
            this.loading = false;
            this.facade.stepActive.next(2)
        });
    }
}


export function emailMatchValidator(): ValidatorFn {
    return (control: AbstractControl) => {
        const email = control.get('email');
        const repeatEmail = control.get('emailConfirmation');

        if (email && repeatEmail && email.value !== repeatEmail.value) {
            return { emailMismatch: true };
        }
        return null;
    };
}
