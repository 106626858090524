<div class="wrapper">
  <button (click)="handleToggleMenu()" color="primary" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>

  <div *ngFor="let item of nav">
    <a class="item" (click)="actionItem(item)">
      <div class="bg-icon">
        <mat-icon [svgIcon]="item.icon"></mat-icon>
        <p>{{ item.label | uppercase }}</p>
        <mat-icon *ngIf="item.moreOptions">
          {{ authorizationOptions ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>
    </a>
    <div *ngIf="authorizationOptions">
      <ng-container *ngFor="let moreOption of item.moreOptions; let first = first">
        <div class="item" (click)="loadOrderAction(moreOption.labelOption)">
          <div class="bg-icon more-option">
            <p>{{ moreOption.labelOption | uppercase }}</p>
          </div>
        </div>
        <ng-container *ngIf="moreOption.more && loadOrderOption">
          <ng-container *ngIf="menuAuthorizations$ | async as menuAuthorizations">
            <ng-container *ngFor="let item of menuAuthorizations">
              <div class="item" (click)="redirectLoadOrderOption(item)">
                <div class="bg-icon procedureOption">
                  <p>{{ item.name | uppercase }}</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
