import { createReducer, on } from "@ngrx/store";
import { affiliateApiActions } from "../actions/affiliate.action";

export interface AffiliateState {
  isLoading: boolean;
  error: boolean;
  affiliate?: any;
  familyGroup?: any;
}

export const initialState: AffiliateState = {
  isLoading: false,
  error: false,
};

export const AffiliateReducer = createReducer(
  initialState,
  on(
    affiliateApiActions.affiliateRequest,
    (state): AffiliateState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    affiliateApiActions.affiliateFailure,
    (state): AffiliateState => ({
      ...state,
      isLoading: false,
      error: true,
    })
  ),

  on(
    affiliateApiActions.affiliateSuccess,
    (state, affiliate): AffiliateState => ({
      ...state,
      affiliate,
      isLoading: false,
      error: false,
    })

  )

);

export const affiliateFeatureKey = 'affiliate';