import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'mi-panel',
        loadChildren: () =>
          import('./dashboard-panel/dashboard-panel.module').then(m => m.DashboardPanelModule),
      },
      {
        path: 'grupo-familiar',
        loadChildren: () =>
          import('./dashboard-my-family-group/dashboard-my-family-group.module').then(
            m => m.DashboardMyFamilyGroupModule
          ),
      },
      {
        path: 'credenciales',
        loadChildren: () =>
          import('./dashboard-credential/dashboard-credential.module').then(
            m => m.DashboardCredentialModule
          ),
      },
      {
        path: 'tu-cobertura',
        loadChildren: () =>
          import('./dashboard-your-coverage/dashboard-your-coverage.module').then(
            m => m.DashboardYourCoverageModule
          ),
      },
      {
        path: 'estado-de-cuenta',
        loadChildren: () =>
          import('./dashboard-account-state/dashboard-account-state.module').then(
            m => m.DashboardAccountStateModule
          ),
      },
      {
        path: 'tus-autorizaciones',
        loadChildren: () =>
          import('./dashboard-authorizations/dashboard-authorizations.module').then(
            m => m.DashboardAuthorizationsModule
          ),
      },
      {
        path: 'turnos-administrativos',
        loadChildren: () =>
          import('./dashboard-administrative-shifts/dashboard-administrative-shifts.module').then(
            m => m.DashboardAdministrativeShiftsModule
          ),
      },
      {
        path: 'asistencia-al-viajero',
        loadChildren: () =>
          import('./dashboard-travel-assistance/dashboard-travel-assistance.module').then(
            m => m.DashboardTravelAssistanceModule
          ),
      },
      {
        path: 'notificaciones',
        loadChildren: () =>
          import('./dashboard-notifications/dashboard-notifications.module').then(
            m => m.DashboardNotificationsModule
          ),
      },
      {
        path: 'baja-de-servicio',
        loadChildren: () =>
          import('./dashboard-service-outage/dashboard-service-outage.module').then(
            m => m.DashboardServiceOutageModule
          ),
      },
      {
        path: 'cartilla-medica',
        loadChildren: () =>
          import('./dashboard-medical-card/dashboard-medical-card.module').then(
            m => m.DashboardMedicalCardModule
          ),
      },
      {
        path: '**',
        redirectTo: 'mi-panel',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
