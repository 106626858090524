import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'nobis-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipNavComponent implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}
