import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';
import { UserProfile } from '../model/affiliate.interfaces';
import { Store } from '@ngrx/store';
import { authApiActions } from 'src/app/store/actions/authentication.actions';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private httpClient = inject(HttpClient);
  private store = inject(Store);

  public login(payload: Partial<{ docType: number; docNumber: number; password: string }>): Observable<UserProfile> {
    const url = `${environment.apiUrl}login`;
    return this.httpClient.post<Response<UserProfile>>(url, payload).pipe(map(req => req.data));
  }

  public logout(): void {
    this.store.dispatch(authApiActions.logout());
  }
}