
export const page = [
  { label: 'Tu panel general', icon: 'dashboard', relativePath: 'mi-panel' },
  { label: 'Tu grupo familiar', icon: 'family-group', relativePath: 'grupo-familiar' },
  { label: 'Credenciales', icon: 'credential', relativePath: 'credenciales' },
  { label: 'Tu cobertura', icon: 'heart-coverage', relativePath: 'tu-cobertura' },
  { label: 'Cartilla medica', icon: 'medical-coverage', relativePath: 'cartilla-medica' },
  { label: 'Estado de cuenta', icon: 'payments', relativePath: 'estado-de-cuenta' },
  {
    label: 'Tus Autorizaciones', icon: 'stethoscope', relativePath: 'tus-autorizaciones',
    moreOptions: [{
      labelOption: 'Cargar pedido',
      more: true
    },
    {
      labelOption: 'Consultar estado',
      relativePath: 'consultar-estado'
    }
  
  ]
  },
  { label: 'Turnos administrativos', icon: 'calendar', relativePath: 'turnos-administrativos' },
  { label: 'Asistencia al viajero', icon: 'travel', relativePath: 'asistencia-al-viajero' },
  { label: 'Notificaciones', icon: 'notifications', relativePath: 'notificaciones' },
  { label: 'Baja de servicio', icon: 'service-cancellation', relativePath: 'baja-de-servicio' },
]