import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenEmail'
})
export class HiddenEmailPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const parts = value.split('@');
    const username = parts[0];
    const domain = parts[1];

    const hiddenUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);

    return `${hiddenUsername}@${domain}`;
  }
}