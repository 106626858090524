import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { authApiActions } from 'src/app/store/actions/authentication.actions';
import { selectIsAuthenticated } from 'src/app/store/selectors/authentication.selectors';

@Injectable()
export class LoginFacade {
  private store = inject(Store);

  public get isAuthenticated(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated).pipe(first(isAuthenticated => isAuthenticated));
  }
  public submitLogin(formValue: any): void {
    this.store.dispatch(
      authApiActions.loginRequest({
        docType: formValue.documentType,
        docNumber: formValue.document,
        password: formValue.password,
      })
    );
  }
  public dispachDeleteErrorLogin(): void {
    this.store.dispatch(authApiActions.deleteErrorLogin());
  }
}
