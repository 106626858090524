import { createReducer, on } from "@ngrx/store";
import { categoriesApiActions } from "../actions/categories.actions";
import { Category } from "src/app/core/model/plan.interfaces";

export interface CategoriesState {
  isLoading: boolean;
  error: boolean;
  categories?: any;
}

export const initialState: CategoriesState = {
  isLoading: false,
  error: false,
};

export const categoriesReducer = createReducer(
  initialState,
  on(
    categoriesApiActions.categoriesRequest,
    (state): CategoriesState => ({ ...state, isLoading: true, error: false })
  ),
  on(
    categoriesApiActions.categoriesFailure,
    (state): CategoriesState => ({ ...state, isLoading: false, error: true })
  ),
  on(
    categoriesApiActions.categoriesSuccess,
    (state, payload): CategoriesState => {
      return {
        ...state, categories: payload.categories, isLoading: false, error: false
      }
    }
  ),
  on(
    categoriesApiActions.getCategoryDetailRequest,
    (state, payload): CategoriesState => {
      return {
        ...state,
        categories: state.categories.map((category: any) => (
          { ...category, isLoadingDetail: category.id === payload.categoryId }
        ))
      };
    }
  ),
  on(
    categoriesApiActions.getCategoryDetailSuccess,
    (state, categoryDetail): CategoriesState => {
      const newCategories = state.categories.map((category: any) =>
        category.id === categoryDetail.categoryId ?
          { ...category, detail: categoryDetail.data, isLoadingDetail: false } :
          category);

      return {
        ...state,
        categories: newCategories
      }
    }
  ),
  on(categoriesApiActions.setCategory, (state, payload): CategoriesState => {
    const mutatedCategories: any[] = JSON.parse(JSON.stringify(state.categories));
    mutatedCategories.forEach((category) => {
      category.isOpen = payload.category.id === category.id ? payload.category.isOpen : false;
    });
    return { ...state, categories: mutatedCategories };
  })
);
export const categoriesFeatureKey = 'categories';