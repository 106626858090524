import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { FamilyGroupService } from 'src/app/core/services/familyGroup.service';
import { familyGroupApiActions } from '../actions/familyGroup.actions';

@Injectable()
export class FamilyGroupEffects {
  constructor(private actions$: Actions, private familyGroupService: FamilyGroupService) { }

  familyGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(familyGroupApiActions.familyGroupRequest.type),
      filter((action: Action & { benGrId: number }) => !!action.benGrId),
      switchMap((action: Action & { benGrId: number }) =>
        this.familyGroupService.getFamilyGroup(action.benGrId).pipe(
          map(payload => familyGroupApiActions.familyGroupSuccess({ familyGroup: payload })),
          catchError(() => of(familyGroupApiActions.familyGroupFailure({ error: 'Error on familyGroup' })))
        )
      )
    );
  });

}
