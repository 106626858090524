import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Response } from '../model/response';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private httpClient = inject(HttpClient);


    public getNotificationAuthorizations(benId: number): Observable<NotificationAuthorizations[]> {
        const url = `${environment.apiUrl}affiliates/${benId}/authorizations`
        return this.httpClient.get<Response<NotificationAuthorizations[]>>(url).pipe(
            map(({ data }) => data)
        )
    }

    public getNotification(benId: number, page: number): Observable<Notification[]> {
        const url = `${environment.apiUrl}affiliates/${benId}/notifications?page=${page}`
        return this.httpClient.get<Response<Notification[]>>(url).pipe(
            map(({ data }) => data)
        )
    }
}

export interface NotificationAuthorizations {
    id: number;
    subject: string;
    body: string;
    icon: string;
    read: boolean;
    date: string;
    humanDate: string;
}

export interface Notification {
    id: number;
    subject: string;
    body: string;
    read: boolean;
    date: string;
    humanDate: string;
    createdAt: string;
    readAt: string | null;
    sentAt: string | null;
}