import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'nobis-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
  animations: [
    trigger('fadeIn', [
        transition(':enter', [
            query(':self', [style({ opacity: 0 }), stagger(100, animate('500ms ease-out', style({ opacity: 1 })))])
        ])
    ])
]
})
export class RegisterSuccessComponent {

}
