import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipNavComponent } from './tooltip.component';


@NgModule({
    declarations: [TooltipNavComponent],
    imports: [CommonModule],
    exports: [TooltipNavComponent],
    providers: [],
})
export class TooltipNavModule { }