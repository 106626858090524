import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/plan.interfaces';
import { Response } from '../model/response';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    private httpClient = inject(HttpClient);
    public getCategories(): Observable<Category[]> {
        const url = `${environment.apiUrl}categories`
        return this.httpClient.get<Response<Category[]>>(url).pipe(
            map(({ data }) => data)
        )
    }

    public getCategoryDetailById(idPlan: number, IdCategory: number): Observable<any> {
        const url = `${environment.apiUrl}plans/${idPlan}/${IdCategory}/coverages`
        return this.httpClient.get<Response<any>>(url).pipe(map(({ data }) => data))
    }
}