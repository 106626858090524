import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { authorizationsApiActions } from 'src/app/store/actions/authorizatios.actions';
import { selectIsAuthenticated } from 'src/app/store/selectors/authentication.selectors';
import { authApiActions } from '../../store/actions/authentication.actions';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class DashboardFacade {
  private store = inject(Store);
  private authenticationService = inject(AuthenticationService);

  public get isAuthenticated(): Observable<boolean> {
    return this.store
      .select(selectIsAuthenticated)
      .pipe(first(isAuthenticated => !isAuthenticated));
  }

  public dispatchAuthorizations(id: number) {
    this.store.dispatch(authorizationsApiActions.selectAuthorizationsRequest({ id }));
  }


  public logOut() {
    this.authenticationService.logout()
  }
}
