import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    private httpClient = inject(HttpClient);

    public registerAccount(payload: Register): any {
        const url = `${environment.apiUrl}register`;
        return this.httpClient.post<Response<any>>(url, payload)
    }
   
}

export interface Register {
    docType: number,
    docNumber: number,
    email: string,
    emailConfirmation: string
}