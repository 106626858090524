import { Action, ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { authApiActions } from './actions/authentication.actions';

export const rehydratation =
    (reducer: ActionReducer<any>): ActionReducer<any> =>
        (state: any, action: Action) => {
            if (action != null && action.type === INIT) {
                const storedState = localStorage.getItem('state');
                if (storedState) {
                    try {
                        return JSON.parse(storedState);
                    } catch {
                        localStorage.removeItem('state');
                    }
                }
            }
            const nextState = reducer(state, action);

            localStorage.setItem('state', JSON.stringify(nextState));
            return reducer(state, action);
        };


export const logout =
    (reducer: ActionReducer<any>): ActionReducer<any> =>
        (state: any, action: Action) => {
            if (action != null && action.type === authApiActions.logout.type) {
                localStorage.removeItem('state');
                return {
                    "auth": {
                        "isLoggingIn": false,
                        "isAuthenticated": false,
                        "error": false
                    },
                    "authorizations": {
                        "isLoading": false,
                        "error": false,
                        "formData": {}
                    },
                    "familyGroup": {
                        "isLoading": false,
                        "error": false
                    },
                    "affiliate": {
                        "isLoading": false,
                        "error": false
                    },
                    "categories": {
                        "isLoading": false,
                        "error": false
                    },
                    "notifications": {},
                    "accountStatus": {}
                };
            }

            return reducer(state, action);
        };
export const metaReducers: MetaReducer[] = [rehydratation, logout];